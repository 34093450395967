import React from 'react'
import Img from './media/ContactUs.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Img} ></img>
        
        
      </header>
    </div>
  );
}

export default App;
